/* autoprefixer grid: autoplace */

:root {
	--fontFamilyBase: "Tandelle", sans-serif;
}

html, body {
	background: #ffffff;
	color: #ffffff;
	scroll-behavior: smooth;
	-webkit-overflow-scrolling: touch;
	font-family: var(--fontFamilyBase);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow: hidden;
	height: 100%;
    -webkit-text-size-adjust: none;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

* {
	-webkit-tap-highlight-color: transparent;
}

#root {
	scroll-snap-type: y mandatory;
	overflow: auto;
	height: 100%;
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--fontFamilyBase);
	font-weight: bold;
}

h1 {
	font-size: 54px;
}

h2 {
    font-size: 46px;
}

h3 {
    font-size: 36px;
}

h4 {
    font-size: 24px;
}

input,
button,
select {
	outline: none;
	font-family: var(--fontFamilyBase);
}

button {
	cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	transition: background-color 5000s ease-in-out 0s;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select,
select:focus,
textarea {
	font-size: 16px !important;
}

/* Remove Arrows/Spinners */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}