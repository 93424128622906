@font-face {
    font-family: 'Tandelle';
    src: url('../fonts/Tandelle-bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Tandelle';
    src: url('../fonts/Tandelle-bold-italic.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Tandelle';
    src: url('../fonts/Tandelle-regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Normalidad';
    src: url('../fonts/Normalidad-black.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Normalidad';
    src: url('../fonts/Normalidad-bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Normalidad';
    src: url('../fonts/Normalidad-medium.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Normalidad';
    src: url('../fonts/Normalidad-light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}